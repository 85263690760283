import { Interpolation } from "@emotion/core";
import { grid } from "../../design-tokens/grid";
import { Colors } from "../../design-tokens/colors";
import { Device, DarkTheme } from "../../design-tokens/media-queries";
import { Border } from "../../design-tokens/borders";
import { Fonts } from "../../design-tokens/fonts";

const eventContainer: Interpolation = {
  position: "relative",
  display: "flex",
  paddingBottom: grid(2),

  ":last-child": {
    paddingBottom: 0,
  },

  [Device.DesktopTablet]: {
    paddingBottom: grid(3),
  },
};

const timeContainer: Interpolation = {
  position: "relative",
  width: grid(14),
  flexShrink: 0,
  marginRight: grid(2),

  [Device.Mobile]: {
    width: grid(7.5),
    marginRight: grid(1),
  },

  "&::after": {
    position: "absolute",
    left: "100%",
    top: 17,
    content: `''`,
    display: "block",
    width: grid(2),
    borderTop: `2px solid ${Colors.BorderGrey}`,

    [DarkTheme]: {
      borderTop: `2px solid ${Colors.DarkModeBorder}`,
    },
  },
};

const timeLabel: Interpolation = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: grid(3.5),
  color: Colors.White,
  fontWeight: Fonts.Weight.SemiBold,
  padding: `${grid(0.5)} ${grid(1)}`,
  backgroundColor: Colors.Blue,
  borderRadius: Border.Radius,
};

const yearLabel: Interpolation = {
  display: "block",
  textAlign: "center",
};

const timeDesktop: Interpolation = {
  [Device.Mobile]: {
    display: "none",
  },
};

const timeMobile: Interpolation = {
  [Device.DesktopTablet]: {
    display: "none",
  },
};

const eventLine: Interpolation = {
  position: "absolute",
  height: "100%",
  left: 166,
  borderLeft: `2px solid ${Colors.BorderGrey}`,
  zIndex: 0,

  [DarkTheme]: {
    borderLeft: `2px solid ${Colors.DarkModeBorder}`,
  },

  [Device.Mobile]: {
    left: 91,
  },
};

const eventIndicator: Interpolation = {
  position: "relative",
  flexShrink: 0,
  width: 15,
  height: 15,
  backgroundColor: Colors.Blue,
  borderRadius: "50%",
  border: `2px solid ${Colors.White}`,
  boxShadow: `0 0 0 2px ${Colors.Blue}`,
  marginTop: grid(1),
  marginRight: grid(2),
  zIndex: 1,

  [DarkTheme]: {
    backgroundColor: Colors.DarkModeTextBlue,
    border: `2px solid ${Colors.DarkModeBackground}`,
    boxShadow: `0 0 0 2px ${Colors.DarkModeTextBlue}`,
  },

  [Device.Mobile]: {
    marginRight: grid(1),
  },
};

const eventContent: Interpolation = {
  maxWidth: "calc(100% - 200px)",
  marginTop: grid(0.5),

  [Device.Mobile]: {
    maxWidth: "calc(100% - 110px)",
  },
};

const eventLabel: Interpolation = {
  fontSize: "20px",
  fontWeight: Fonts.Weight.SemiBold,

  "> span": {
    fontWeight: Fonts.Weight.Bold,
    color: Colors.Red,
  },

  [Device.Mobile]: {
    fontSize: "16px",
  },
};

const tag: Interpolation = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  height: grid(2.5),
  fontSize: "12px",
  fontWeight: Fonts.Weight.Bold,
  padding: `${grid(0.5)} ${grid(1)}`,
  backgroundColor: Colors.LightGrey,
  borderRadius: Border.RadiusSm,
  marginBottom: grid(1),

  [DarkTheme]: {
    backgroundColor: Colors.DarkModeBlack,
  },
};

const toggleCTA: Interpolation = {
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.Blue,
  cursor: "pointer",

  [DarkTheme]: {
    color: Colors.DarkModeTextBlue,
  },
};

const eventDescription: Interpolation = {
  marginTop: grid(1),
  transition: "all 0.2s ease",
  overflow: "hidden",
  "> div": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    a: {
      wordBreak: "break-word",
    },
  },
};

const styles = {
  eventContainer,
  timeContainer,
  yearLabel,
  timeLabel,
  timeDesktop,
  timeMobile,
  eventIndicator,
  eventLine,
  eventContent,
  eventLabel,
  tag,
  toggleCTA,
  eventDescription,
};

export default styles;
