import * as React from "react";
import Actionable from "../../../../ui-library/actionable/actionable";
import { EventCategory } from "../../../../../utils/analytics";
import { Covid19AskCountryGovResTimelineQueryQuery } from "../../../../../../graphql-types";
import { GetResponseTimeSeriesByCountry_responsesTimeSeriesByCountry_data } from "../../../../../apollo-types/GetResponseTimeSeriesByCountry";
import Row from "../../../../ui-library/column-system/row";
import Column from "../../../../ui-library/column-system/column";
import Container from "../../../../ui-library/container/container";
import styles from "./government-response-timeline-page.styles";
import GovernmentResponseVsCases from "./government-response-vs-cases";
import GovernmentResponseTimeline from "./government-response-timeline";

const tabs = ["Response timeline", "Response versus cases"];

interface GovernmentResponseTimelinePageProps {
  intentName: string;
  country: string;
  countryName: string;
  responseTimeline: Covid19AskCountryGovResTimelineQueryQuery["askAboutQuery"]["responsesChangeTimelineByCountry"];
  responseTimeSeries: Covid19AskCountryGovResTimelineQueryQuery["askAboutQuery"]["responsesTimeSeriesByCountry"];
  covidData: Covid19AskCountryGovResTimelineQueryQuery["askAboutQuery"]["covidCaseOwidByCountry"];
}

const GovernmentResponseTimelinePage: React.FC<GovernmentResponseTimelinePageProps> = ({
  intentName,
  country,
  countryName,
  responseTimeline,
  responseTimeSeries,
  covidData,
}) => {
  const [activeTab, setActiveTab] = React.useState<string>(tabs[0]);

  return (
    <div>
      <Container>
        <Row>
          {tabs.map((tab) => (
            <Column key={tab} spanLg={6} spanMd={6} spanSm={6} spanXs={6}>
              <Actionable
                role="button"
                ariaLabel={tab}
                css={[styles.tab, activeTab === tab && styles.activeTab]}
                onClick={() => setActiveTab(tab)}
                analytics={{
                  category: EventCategory.CountryGovResponseTimelinePage,
                  action: `Click - ${tab}`,
                  label: tab,
                }}
              >
                {tab}
              </Actionable>
            </Column>
          ))}
        </Row>
      </Container>

      {activeTab === tabs[0] && (
        <GovernmentResponseTimeline
          intentName={intentName}
          country={country}
          countryName={countryName}
          responseTimeline={responseTimeline}
        />
      )}
      {activeTab === tabs[1] && (
        <GovernmentResponseVsCases
          responseTimeline={responseTimeline?.data}
          responseTimeSeries={
            responseTimeSeries?.data as GetResponseTimeSeriesByCountry_responsesTimeSeriesByCountry_data[]
          }
          countryName={countryName}
          data={covidData?.data}
        />
      )}
    </div>
  );
};

export default GovernmentResponseTimelinePage;
