import * as React from "react";

import { PageProps, graphql } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo/seo";
import PageContainer from "../../components/ui-library/page-container/page-container";
import { LogoBunny } from "../../components/site/header/header";
import PageHeading from "../../components/ui-library/page-heading/page-heading";
import Container from "../../components/ui-library/container/container";
import SuggestedIntent from "../../components/common-page-sections/suggested-intent/suggested-intent";
import CollectQAndA from "../../components/common-page-sections/collect-q-and-a/collect-q-and-a";
import Feedback from "../../components/feedback/feedback";
import SocialShare from "../../components/ui-library/social-share/social-share";
import { getCountryWithResUrl } from "../../utils/question-url";
import Navigation from "../../components/site/navigation/navigation";
import { TOP_NAV_ITEM } from "../../constants/top-navigations";
import { Covid19AskCountryGovResTimelineQueryQuery } from "../../../graphql-types";
import { Topic } from "../../types/topics";
import GovernmentResponseTimelinePage from "../../components/covid-19/covid-intent/intent-specific-answers/government-response-timeline/government-response-timeline-page";
import ECVBanner from "../../components/covid-19/third-party-banner/ecv-banner";

interface IntentData {
  countryName: string;
  country: string;
  alias: string;
}

type Covid19AskGovResTimelineProps = PageProps<
  Covid19AskCountryGovResTimelineQueryQuery,
  IntentData
>;

const Covid19AskCountryGovResTimeline: React.FC<Covid19AskGovResTimelineProps> = ({
  pageContext: { countryName, country, alias },
  data,
}) => {
  const responseTimeline = data.askAboutQuery.responsesChangeTimelineByCountry;
  const responseTimeSeries = data.askAboutQuery.responsesTimeSeriesByCountry;
  const covidData = data.askAboutQuery.covidCaseOwidByCountry;

  const intentName = `What's the government response timeline for ${countryName}?`;

  const description = `Find out what policies ${countryName} has implemented to combat the pandemic.`;

  return (
    <Layout
      topic={Topic.COVID19}
      description="coronavirus"
      headerUrl="/covid-19/"
      logoBunny={LogoBunny.FaceMask}
    >
      <SEO
        title={intentName}
        description={description}
        imageUrl={`/media/images/social-share/covid-19/country-gov-res-timeline-${alias}.jpg`}
      />

      <Navigation topic={Topic.COVID19} activeItem={TOP_NAV_ITEM.Insights} />

      <PageContainer>
        <PageHeading>{intentName}</PageHeading>

        <Container fullWidthOnMobile={true}>
          <GovernmentResponseTimelinePage
            countryName={countryName}
            country={country}
            intentName={intentName}
            responseTimeline={responseTimeline}
            responseTimeSeries={responseTimeSeries}
            covidData={covidData}
          />

          <Feedback intentName={intentName} topic={Topic.COVID19} />
        </Container>

        <Container>
          <SuggestedIntent
            suggestedIntent={{
              alias: `what-is-the-covid-19-situation-in-${alias}`,
              displayName: `What is the COVID-19 situation in ${countryName}?`,
            }}
            topic={Topic.COVID19}
          />

          <SocialShare
            title={intentName}
            description={description}
            url={getCountryWithResUrl(alias)}
          />

          <ECVBanner />

          <CollectQAndA />
        </Container>
      </PageContainer>
    </Layout>
  );
};

export default Covid19AskCountryGovResTimeline;

export const query = graphql`
  query Covid19AskCountryGovResTimelineQuery($country: String!) {
    askAboutQuery {
      responsesChangeTimelineByCountry(country: $country) {
        isoCode
        name
        data {
          date
          policyType
          policyValue
          notes
        }
      }
      responsesTimeSeriesByCountry(country: $country) {
        isoCode
        name
        data {
          date
          stringencyIndexForDisplay
          governmentResponseIndexForDisplay
          containmentHealthIndexForDisplay
          economicSupportIndexForDisplay
        }
      }
      covidCaseOwidByCountry(country: $country) {
        data {
          date
          totalCases
          averageNewCases
        }
      }
    }
  }
`;
