import { Colors } from "./colors";
import { DarkTheme } from "./media-queries";

export const TooltipContainerStyles = {
  border: 0,
  borderRadius: "8px",
  fontSize: 12,
  color: Colors.Black,
  boxShadow: "2px 2px 5px 3px rgba(0,0,0,0.15)",

  [DarkTheme]: {
    color: Colors.DarkModeWhite,
    backgroundColor: Colors.DarkModeBlack,
  },
};
