import { Interpolation } from "@emotion/core";
import { Device, DarkTheme } from "../../../../design-tokens/media-queries";
import { grid } from "../../../../design-tokens/grid";
import { Colors } from "../../../../design-tokens/colors";
import { Border } from "../../../../design-tokens/borders";
import { Fonts } from "../../../../design-tokens/fonts";

const tab: Interpolation = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: grid(6),
  textAlign: "center",
  lineHeight: 1.2,
  fontWeight: Fonts.Weight.SemiBold,
  backgroundColor: Colors.White,
  padding: `${grid(1)} ${grid(1.5)}`,
  borderRadius: Border.Radius,

  [Device.DesktopTablet]: {
    flex: 1,
    height: grid(10),
    fontSize: 24,
    padding: grid(2),
  },

  [DarkTheme]: {
    backgroundColor: Colors.DarkModeBackground,
  },
};

const activeTab: Interpolation = {
  position: "relative",
  color: Colors.White,
  backgroundColor: Colors.Blue,

  [DarkTheme]: {
    backgroundColor: Colors.Blue,
  },

  "&::after": {
    content: `''`,
    display: "block",
    position: "absolute",
    top: "calc(100% - 12px)",
    left: "calc(50% - 14px)",
    width: grid(2),
    height: grid(2),
    backgroundColor: Colors.Blue,
    borderRadius: 3,
    transform: "rotate(45deg)",
  },
};

const styles = {
  tab,
  activeTab,
};

export default styles;
