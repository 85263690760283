export const COVID_SOURCES = {
  OWID: {
    title: "Our World in Data",
    url: "https://github.com/owid/covid-19-data",
  },
  NYT: {
    title: "New York Times",
    url: "https://github.com/nytimes/covid-19-data",
  },
  JHU: {
    title: "Johns Hopkins",
    url:
      "https://github.com/CSSEGISandData/COVID-19/tree/master/csse_covid_19_data",
  },
  BusinessInsider: {
    title: "businessinsider.com.au",
    url:
      "https://www.businessinsider.com.au/coronavirus-pandemic-timeline-history-major-events-2020-3",
  },
  Oxford: {
    title: "Oxford COVID-19 Government Response Tracker",
    url:
      "https://www.bsg.ox.ac.uk/research/research-projects/coronavirus-government-response-tracker",
  },
};
