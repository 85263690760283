import * as React from "react";
import ContentCard from "../../../../ui-library/content-card/content-card";
import { RainBow, Colors } from "../../../../design-tokens/colors";
import SourceLink from "../../../../ui-library/source-link/source-link";
import { EventCategory } from "../../../../../utils/analytics";
import CardContent from "../../../../ui-library/content-card/card-content";
import CardHeading from "../../../../ui-library/content-card/card-heading";
import CardSubHeading from "../../../../ui-library/content-card/card-sub-heading";
import TimelinePoint from "../../../../ui-library/timeline-point/timeline-point";
import { grid } from "../../../../design-tokens/grid";
import { Device } from "../../../../design-tokens/media-queries";
import TextAnswerTip from "../../../../ui-library/answers/text-answer-tip";
import { Covid19AskCountryGovResTimelineQueryQuery } from "../../../../../../graphql-types";
import { COVID_SOURCES } from "../../../../../constants/sources";
import { POLICY_VALUE_DETAIL } from "./policy-value-explaination";

const colorMapping: { [key: string]: string } = {
  C: Colors.Red,
  E: RainBow.SkyBlue,
  H: RainBow.Teal,
};

interface GovernmentResponseTimelineProps {
  intentName: string;
  country: string;
  countryName: string;
  responseTimeline: Covid19AskCountryGovResTimelineQueryQuery["askAboutQuery"]["responsesChangeTimelineByCountry"];
}

const GovernmentResponseTimeline: React.FC<GovernmentResponseTimelineProps> = ({
  countryName,
  responseTimeline,
}) => {
  return (
    <ContentCard>
      <CardContent>
        <CardHeading>
          COVID-19 government response timeline for {countryName}
        </CardHeading>

        <CardSubHeading>
          Find out what policies {countryName} has implemented to combat the
          pandemic.
        </CardSubHeading>

        <div
          css={{
            marginBottom: grid(3),

            [Device.DesktopTablet]: {
              marginBottom: grid(4),
            },
          }}
        >
          {!!responseTimeline &&
            responseTimeline.data?.map((event) => {
              const date = new Date(event.date);
              const year = date.getFullYear();

              const color =
                colorMapping[event.policyType[0]] || RainBow.SkyBlue;
              const policy = POLICY_VALUE_DETAIL[event.policyType.slice(0, 2)];

              let suffix = "";

              if (policy.measurement === "ordinal") {
                suffix = ` - ${policy.coding[event.policyValue]}`;
              }

              if (policy.measurement === "usd") {
                suffix = ` - ${event.policyValue} USD`;
              }

              let tag: string | undefined = undefined;

              if (policy.measurement === "ordinal") {
                tag = `Level ${event.policyValue} of ${
                  policy.coding.length - 1
                }`;
              }

              return (
                <TimelinePoint
                  key={`${event.date}${event.notes.slice(0, 16)}`}
                  date={event.date}
                  label={`${event.policyType}${suffix}`}
                  tag={tag}
                  description={event.notes}
                  color={color}
                  showYear={year === 2019}
                />
              );
            })}
        </div>

        <TextAnswerTip
          title="About government response data"
          description="Data is collected from public sources by a team of over one hundred Oxford University students and staff from every part of the world. Please note: not all countries are equally up to date; for certain countries, some indicators will be missing for some days."
        />

        <SourceLink
          sources={[COVID_SOURCES.Oxford]}
          eventCategory={EventCategory.CountryGovResponseTimelinePage}
        />
      </CardContent>
    </ContentCard>
  );
};

export default GovernmentResponseTimeline;
