import { Interpolation } from "@emotion/core";
import { transparentize } from "polished";
import { Device, DarkTheme } from "../../design-tokens/media-queries";
import { Colors } from "../../design-tokens/colors";
import { grid } from "../../design-tokens/grid";
import { Fonts } from "../../design-tokens/fonts";

const container: Interpolation = {
  display: "flex",
  marginBottom: grid(4),

  [Device.Mobile]: {
    marginBottom: grid(3),
    "> div": {
      width: "100%",
    },
  },
};

const scroll: Interpolation = {
  [Device.Mobile]: {
    overflowX: "scroll",
    WebkitOverflowScrolling: "touch",
    overflowY: "hidden",
    marginLeft: grid(-2),
    marginRight: grid(-2),

    "> div": {
      width: "auto !important",
    },

    "&::before, &::after": {
      content: `''`,
      display: "block",
      width: grid(2),
      flexShrink: 0,
    },

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
};

const borderedContainer: Interpolation = {
  position: "relative",
  flexShrink: 0,
  display: "flex",
  minHeight: "50px",
  backgroundColor: transparentize(0.92, "#767FA2"),
  padding: grid(0.5),
  borderRadius: "50px",
  userSelect: "none",

  [DarkTheme]: {
    backgroundColor: transparentize(0.3, Colors.DarkModeDeepBlack),
  },

  [Device.DesktopTablet]: {
    width: "100%",
  },

  [Device.Mobile]: {
    minWidth: "100%",
  },
};

const defaultLabel: Interpolation = {
  position: "relative",
  zIndex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  backgroundColor: "transparent",
  borderRadius: "50px",
  overflow: "hidden",
  WebkitTapHighlightColor: "transparent",
  cursor: "pointer",

  "&:focus": {
    outline: "none",
  },

  "> *, > *::before, > *::after": {
    userSelect: "none",
  },

  [Device.Mobile]: {
    minWidth: "64px",
    flexShrink: 0,
  },
};

const uniWidth: Interpolation = {
  flex: 1,
};

const labelText: Interpolation = {
  position: "relative",
  zIndex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  height: "100%",
  fontSize: "16px",
  lineHeight: "20px",
  letterSpacing: "0.05px",
  padding: `${grid(1)} ${grid(1.5)}`,

  [Device.DesktopTablet]: {
    "&:hover": {
      fontWeight: Fonts.Weight.SemiBold,
      letterSpacing: 0,
      color: Colors.Blue,
      transition: "color 0.2s ease",

      [DarkTheme]: {
        color: Colors.DarkModeTextBlue,
      },
    },
  },

  "&::after": {
    position: "absolute",
    top: 0,
    left: 0,
    display: "block",
    height: 0,
    visibility: "hidden",
    content: "attr(title)",
    fontWeight: Fonts.Weight.SemiBold,
    padding: `${grid(1)} ${grid(2)}`,
    zIndex: 0,
  },
};

const selected: Interpolation = {
  backgroundColor: Colors.Blue,
  "> span:first-of-type": {
    color: `${Colors.White} !important`,
    fontWeight: Fonts.Weight.Bold,
  },

  pointerEvents: "none",
};

const styles = {
  scroll,
  container,
  borderedContainer,
  defaultLabel,
  uniWidth,
  labelText,
  selected,
};

export default styles;
