import * as React from "react";
import formatDate from "dateformat";
import { transparentize } from "polished";
import { DarkTheme } from "../../design-tokens/media-queries";
import { grid } from "../../design-tokens/grid";
import styles from "./timeline-point.styles";

interface EventDetailProps {
  label: string;
  description?: string;
  tag?: string;
}

const EventDetail: React.FC<EventDetailProps> = ({
  label,
  description,
  tag,
}) => {
  const [isOpen, setOpen] = React.useState<boolean>();

  return (
    <div css={styles.eventContent}>
      {tag && <div css={styles.tag}>{tag}</div>}
      <div>
        <span
          css={styles.eventLabel}
          dangerouslySetInnerHTML={{ __html: label }}
        />
        {!!description && !isOpen && (
          <span
            css={[styles.toggleCTA, { marginLeft: grid(1) }]}
            onClick={() => setOpen(true)}
          >
            Details
          </span>
        )}
      </div>

      {!!description && (
        <div
          css={[
            styles.eventDescription,
            {
              maxHeight: isOpen ? 1000 : 0,
              overflow: isOpen ? "auto" : "hidden",
            },
          ]}
        >
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <span
            css={[
              styles.toggleCTA,
              { display: "inline-block", marginTop: grid(1) },
            ]}
            onClick={() => setOpen(false)}
          >
            Hide
          </span>
        </div>
      )}
    </div>
  );
};

interface TimelinePointProps {
  date: string;
  label?: string;
  tag?: string;
  description?: string;
  showYear?: boolean;
  color: string;
}

const TimelinePoint: React.FC<TimelinePointProps> = ({
  date,
  label,
  description,
  showYear,
  color,
  tag,
}) => {
  return (
    <div css={styles.eventContainer}>
      <div css={styles.timeContainer}>
        <div
          css={[
            styles.timeLabel,
            {
              backgroundColor: transparentize(0.1, color),
              [DarkTheme]: {
                backgroundColor: transparentize(0.2, color),
              },
            },
          ]}
        >
          <span css={styles.timeDesktop}>
            {formatDate(date, "mmm dS, yyyy")}
          </span>
          <span css={styles.timeMobile}>{formatDate(date, "mmm dd")}</span>
        </div>
        {showYear && (
          <span css={[styles.yearLabel, styles.timeMobile]}>
            {formatDate(date, "yyyy")}
          </span>
        )}
      </div>
      <div css={styles.eventIndicator} />
      <div css={styles.eventLine} />
      {label && (
        <EventDetail label={label} description={description} tag={tag} />
      )}
    </div>
  );
};

export default TimelinePoint;
