import * as React from "react";

import invariant from "ts-invariant";
import {
  EventCategory,
  pushActionableClickEvent,
} from "../../../utils/analytics";
import styles from "./item-picker.styles";

export interface PickerItem {
  label: string;
  value: string;
  testId?: string;
}

interface ItemPickerProps {
  itemPickerName: string;
  items: PickerItem[];
  activeItemValue: string;
  onItemClick: (value: string) => void;
  isScrollable?: boolean;
  isUniWidth?: boolean;
  eventCategory: EventCategory;
}

const ItemPicker: React.FC<ItemPickerProps> = (props) => {
  const {
    eventCategory,
    itemPickerName,
    isScrollable,
    isUniWidth,
    items,
    activeItemValue,
    onItemClick,
  } = props;

  invariant(
    !itemPickerName.includes(" "),
    "Item picker name should be camelcase"
  );

  const itemPickerRef = React.useRef<HTMLDivElement>(null);

  const handleClick = (event?: React.MouseEvent | React.KeyboardEvent) => {
    if (event) {
      const value = event.currentTarget.getAttribute("data-state");
      onItemClick(value!);
    }
  };

  const content = items.map((item, index) => (
    <div
      id={`${itemPickerName}-${index}`}
      role="button"
      aria-label={item.label}
      data-state={item.value}
      data-testid={item.testId}
      css={[
        styles.defaultLabel,
        isUniWidth && styles.uniWidth,
        activeItemValue === item.value && styles.selected,
      ]}
      onClick={(e) => {
        handleClick(e);
        pushActionableClickEvent({
          category: eventCategory,
          action: `Click - ${itemPickerName}`,
          label: item.label,
        });
      }}
      onKeyPress={handleClick}
      title={item.value}
      key={item.value}
    >
      <span css={styles.labelText} title={item.value}>
        {item.label}
      </span>
    </div>
  ));

  return (
    <div css={[styles.container, isScrollable && styles.scroll]}>
      <div ref={itemPickerRef} css={styles.borderedContainer}>
        {content}
      </div>
    </div>
  );
};

export default ItemPicker;

export const ItemPickerSkeleton: React.FC = () => (
  <div css={styles.container}>
    <div css={styles.borderedContainer} />
  </div>
);
